@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.StyledModal .ant-modal-content{
  height: 350px!important;
  display: flex;
  justify-content: center!important;
  flex-direction: column;
}
.StyledModal .ant-modal-footer{
  display: flex;
  justify-content: center!important;
  flex-direction: row;
}

.uploadBtn .ant-upload{
  width: 100%!important;
}


.reportCardTable{
  width: 100%;
  padding: 0;
  background-color: transparent;
  width: 100%;
  margin-bottom: 15px;
  font-size: .9em;
  border-spacing: 0;
  border-collapse: collapse;
  
}


.skeleteonImage{
width: 100%!important;
}


.reportCardTable th{
  background-color: #ffc10b;
  font-family: 'Montserrat', sans-serif!important;
  text-align: left;
  border: 1px solid hsla(0,0%,50.2%,.5019607843137255);
  font-size: 18px;
  padding: 16px;
  font-weight: 600;
}

.reportCardTable td{
  font-family: 'Montserrat', sans-serif!important;
  border: 1px solid hsla(0,0%,50.2%,.5019607843137255);
  text-align: left;
  font-size: 16px;
  padding: 16px;
  font-weight: 600;
}


.fontFamily3{
  font-family: 'Montserrat', sans-serif!important;
}

.FormSelect .ant-select-selector{
  border-radius: 5px!important;
  background: white;
  font-size: 14px;
  width:100%;
  min-height: 45px!important;
  padding: 3px 20px!important;
}